import React, { useEffect } from "react"
import DataEngineering from "../components/DataEngineering"

import Layout from "../components/Layout"
import { Helmet } from "react-helmet"

export default function ServicesPage() {
  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://codefulcrum.com/data-engineering/"
        />
      </Helmet>
      <DataEngineering />
    </Layout>
  )
}
