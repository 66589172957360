import React, { useEffect } from "react"
import Hero from "./Hero"
import Head from "../utils/Head"
import { DataEngItemsSliders, updatedDataEng } from "../shared/ModalSlider/data"

const ModalSlider = React.lazy(() => import("../shared/ModalSlider"))
const EngineeringServices = React.lazy(() => import("./EngineeringServices"))
const EngineeringBenefits = React.lazy(() =>
  import("./EngineeringBenefits/EngineeringBenefits")
)
const WorkContent = React.lazy(() =>
  import("./Workflow/WorkWithUs/WorkContent")
)
const WorkSwiper = React.lazy(() =>
  import("../../components/Homepage/WorkSwiper")
)
const Accordian = React.lazy(() => import("./FAQs/Accordian"))
const ContactUs = React.lazy(() => import("../ProductDesign/Footer/ContactUs"))
const ServicesSection = React.lazy(() => import("./ServicesSection"))

function DataEngContent() {
  return (
    <div>
      <Head
        metaTitle="Top Data Engineering & Data Mangement Services - CodeFulcrum"
        title="Advanced Data Engineering Solutions - CodeFulcrum"
        desc="Convert your business with our comprehensive data engineering solutions. From data engineering consulting to implementation, our team of experts ensures seamless integration and optimized performance."
      />

      <Hero
        id="top"
        btnText="LET'S DISCUSS HOW"
        text="We assist your company in moving to the next level of data utilization, management, and automation by transforming the way you look at your data."
        header="Increase Your Operational Efficiency By Analyzing Large Datasets"
        scroll="#e-services"
      />

      <React.Suspense fallback={<div />}>
        <EngineeringServices />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <EngineeringBenefits />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <ModalSlider
          page="dataeng"
          data={updatedDataEng}
          title={
            "Check out how we help businesses all over the world make the most of their data"
          }
          description={``}
        />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <WorkContent />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <WorkSwiper />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <ServicesSection />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <Accordian />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <ContactUs type="DataEng" />
      </React.Suspense>
    </div>
  )
}

export default DataEngContent
