import React from "react"
import { Helmet } from "react-helmet"

const Head = ({ title, metaTitle, desc }) => {
  return (
    <>
      <Helmet defaultTitle="CodeFulcrum" title={title} defer>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={desc} />
      </Helmet>
    </>
  )
}

export default Head
