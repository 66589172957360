import React from "react"
export const CustomSoftwareItemsSliders = [
  {
    id: 1,
    title: "Product Strategy Workshops",
    description: "Sharpening your vision and learning about your goals",
    content: `The strategy consulting workshops are dynamic and need active engagement from both parties to ensure that your business goals and the end user's expectations are the center of attention. We define the business, user, and system requirements using modern tools and practices. Workshops are essential for kicking off any project and ensuring expectations are aligned from the start of any partnership.`,
  },
  {
    id: 2,
    title: "UI/UX Design",
    description: "Unique and creative design",
    content: `After the custom-tailored workshops, together we work on user personas and customer journeys, and wireframe all the essential features. A clickable prototype is made to clearly show what your product can do, which helps to test ideas with users or entice stakeholders to invest. With every user interaction, we validate our assumptions to reduce potential risks and deliver a set of features that will accomplish your business goals.`,
  },
  {
    id: 3,
    title: "Front-End Development",
    description: "Storyboarding and wireframing",
    content: `After the finalization of the designs, our team starts coding your frontend solution. We are committed to creating custom applications that are optimized for every screen size, from mobile devices to large kiosks. Our focus is on developing responsive web designs (RWD) that offer seamless user experiences across all devices. We utilize the best RWD patterns to ensure that your application is not only visually appealing but also highly functional and accessible.`,
  },
  {
    id: 4,
    title: "Back-End Development",
    description: "Bringing your application idea into reality",
    content: `This is where we set up the backend architecture of your application. On average, it takes us up to 3 months to create a fully functional MVP - a minimum viable product. However, the final development time depends on the app’s scope and complexity.`,
  },
  {
    id: 5,
    title: "QA & Testing",
    description: "Ensuring smooth running of the application",
    content: `We take pride in the quality of our service, which means that our QA testers are an essential part of the team from the beginning of the project, assisting in the development of a safe, secure, and reliable digital product. Before any real user gets to access the platform, we put the app through intensive stress testing to guarantee that your product performs as required across all platforms and operating systems.`,
  },
  {
    id: 6,
    title: "Product Release",
    description: "Launching your app to the market",
    content: `With the MVP ready there’s nothing left to do but to see how your app performs with real users! We’ll take care of the deployment and release, making sure that every individual part of the system is delivered on schedule and works smoothly, without any downtime.`,
  },
  {
    id: 7,
    title: "Maintenance & Support",
    description: "Taking good care of your product",
    content: `Your custom digital product is released, but our cooperation doesn’t necessarily end here. We pride ourselves in building long lasting partnerships. If you’d like to upgrade your product with some additional features over time, if there is a particular enhancement that you’d like to try out or if you simply want us to take care of maintenance and support, we’re always happy to help!`,
  },
]

export const ProductDesignItemsSliders = [
  {
    id: 1,
    title: "Discover",
    description: "Understanding your business goals and challenges",
    content:
      "First, we identify the scope of your project and take the time to understand your product idea and business needs and how the problem statement should abide by the functions of your product, specifically what it does or should do. This includes discussing potential features, technological solutions, and the time required to deliver them. As a result, we can provide you with a general development quotation and timeframe.",
  },
  {
    id: 2,
    title: "Research",
    description: "Market insights & competitive analysis",
    content:
      "A quality product requires a well-defined vision as well as a customer and market-driven strategy. This is why we delve into the specifics of your industry, analyze your competitors' moves, and learn about the latest trends and the needs of your users. Our product design services can assist you in identifying new opportunities for your company and developing a dependable Unique Value Proposition for your product.",
  },
  {
    id: 3,
    title: "Ideate",
    description: "Storyboarding and wireframing",
    content:
      "This is the most crucial step in the design process that demands innovative thinking. During the customer-tailored design sprints, we take our time developing use cases by defining individual requirements. A use case depicts how a user takes specific steps to achieve a specific goal. Use cases function more like a software modeling technique, allowing us to define the features we want to implement. It also resolves any errors that may arise early in the development process. Before implementing anything, we take the time to create a storyboard for each use case and present it to you.We then brainstorm viable solutions, and wireframe all critical features",
  },
  {
    id: 4,
    title: "Prototype",
    description: "Completing the MVP and further iteration",
    content:
      "A clickable prototype is created to clearly demonstrate what your product can do, allowing us to test ideas with users and help you attract more investors. We quickly validate our assumptions with each interaction in order to minimize the potential risks and deliver a more significant set of features that will help you achieve your business goals.",
  },
  {
    id: 5,
    title: "Optimize",
    description: "Data-driven enhancements",
    content:
      "We assist in post-launch product’ testing and optimizing the metrics, redefining business ideas in accordance with proposed UX/UI Design solutions, and developing new features. Our team examines the initial assumptions, examines the data to identify missed opportunities, and devises improvements that can deliver positive results.",
  },
]

export const DataEngItemsSliders = [
  {
    id: 1,
    title: "Understanding ",
    description: "Understanding business needs and technical requirements",
    content:
      "Firstly, our data engineering team carries out the workshops and discovery calls with potential end-users. Then, we get all the necessary information from the technical departments.",
  },
  {
    id: 2,
    title: "Analysing",
    description: "Analysis of existing and future data sources",
    content:
      "At this stage, it is essential to go through current data sources to maximize the value of data. You should identify multiple data sources from which structured and unstructured data may be collected. During this step, our experts will prioritize and assess them.",
  },
  {
    id: 3,
    title: "Building and implementing",
    description: "Building and implementing Data Lake and Pipelines",
    content:
      "Data Lakes are the most cost-effective alternatives for storing data. A data lake is a data repository system that stores raw and processed structured and unstructured data files. A system like stores flat, source, transformed, or raw files.",
  },
  {
    id: 4,
    title: "Automating and deploying",
    description: "Automation and deployment",
    content:
      "The next step is one of the most important parts in data development consulting – DevOps. Our team develops the right DevOps strategy to deploy and automate the data pipeline.",
  },
  {
    id: 5,
    title: "Testing",
    description: "Testing",
    content:
      "Testing, measuring, and learning — are important at the last stage of the Data Engineering Process. DevOps automation is vital at this moment. Data Lakes could be established or accessed, using specific tools such as Hadoop or s3.",
  },
]

export const updatedProductDesign = [
  {
    id: 1,

    cardHeading: "Discover",

    cardText: (
      <>
        {"Understanding your business goals and challenges."} <br></br>
        <br></br>
        {
          "First, we identify the scope of your project and take the time to understand your product idea and business needs and how the problem statement should abide by the functions of your product, specifically what it does or should do. This includes discussing potential features, technological solutions, and the time required to deliver them. As a result, we can provide you with a general development quotation and timeframe."
        }
      </>
    ),
  },
  {
    id: 2,
    cardHeading: "Research",
    cardText: (
      <>
        {"Market insights & competitive analysis"} <br></br>
        <br></br>
        {
          "A quality product requires a well-defined vision as well as a customer and market-driven strategy. This is why we delve into the specifics of your industry, analyze your competitors' moves, and learn about the latest trends and the needs of your users. Our product design services can assist you in identifying new opportunities for your company and developing a dependable Unique Value Proposition for your product."
        }
      </>
    ),
  },
  {
    id: 3,
    cardHeading: "Ideate",

    cardText: (
      <>
        {"Storyboarding and wireframing"} <br></br>
        <br></br>
        {
          "This is the most crucial step in the design process that demands innovative thinking. During the customer-tailored design sprints, we take our time developing use cases by defining individual requirements. A use case depicts how a user takes specific steps to achieve a specific goal. Use cases function more like a software modeling technique, allowing us to define the features we want to implement. It also resolves any errors that may arise early in the development process. Before implementing anything, we take the time to create a storyboard for each use case and present it to you.We then brainstorm viable solutions, and wireframe all critical features"
        }
      </>
    ),
  },
  {
    id: 4,
    cardHeading: "Prototype",
    cardText: (
      <>
        {"Completing the MVP and further iteration"} <br></br>
        <br></br>
        {
          "A clickable prototype is created to clearly demonstrate what your product can do, allowing us to test ideas with users and help you attract more investors. We quickly validate our assumptions with each interaction in order to minimize the potential risks and deliver a more significant set of features that will help you achieve your business goals."
        }
      </>
    ),
  },
  {
    id: 4,
    cardHeading: "Optimize",

    cardText: (
      <>
        {"Data-driven enhancements"} <br></br>
        <br></br>
        {
          "We assist in post-launch product’ testing and optimizing the metrics, redefining business ideas in accordance with proposed UX/UI Design solutions, and developing new features. Our team examines the initial assumptions, examines the data to identify missed opportunities, and devises improvements that can deliver positive results."
        }
      </>
    ),
  },
]
export const updatedCustomSoftware = [
  {
    id: 1,

    cardHeading: "Product Strategy Workshops",

    cardText: (
      <>
        {"Sharpening your vision and learning about your goals"} <br></br>
        <br></br>
        {
          "The strategy consulting workshops are dynamic and need active engagement from both parties to ensure that your business goals and the end user's expectations are the center of attention. We define the business, user, and system requirements using modern tools and practices. Workshops are essential for kicking off any project and ensuring expectations are aligned from the start of any partnership."
        }
      </>
    ),
  },
  {
    id: 2,
    cardHeading: "UI/UX Design",

    cardText: (
      <>
        {"Unique and creative design"} <br></br>
        <br></br>
        {
          "After the custom-tailored workshops, together we work on user personas and customer journeys, and wireframe all the essential features. A clickable prototype is made to clearly show what your product can do, which helps to test ideas with users or entice stakeholders to invest. With every user interaction, we validate our assumptions to reduce potential risks and deliver a set of features that will accomplish your business goals."
        }
      </>
    ),
  },
  {
    id: 3,
    cardHeading: "Front-End Development",

    cardText: (
      <>
        {"Storyboarding and wireframing"} <br></br>
        <br></br>
        {
          "After the finalization of the designs, our team starts coding your frontend solution. We are committed to creating custom applications that are optimized for every screen size, from mobile devices to large kiosks. Our focus is on developing responsive web designs (RWD) that offer seamless user experiences across all devices. We utilize the best RWD patterns to ensure that your application is not only visually appealing but also highly functional and accessible."
        }
      </>
    ),
  },
  {
    id: 4,
    cardHeading: "Back-End Development",

    cardText: (
      <>
        {"Bringing your application idea into reality"} <br></br>
        <br></br>
        {
          "This is where we set up the backend architecture of your application. On average, it takes us up to 3 months to create a fully functional MVP - a minimum viable product. However, the final development time depends on the app’s scope and complexity."
        }
      </>
    ),
  },
  {
    id: 5,
    cardHeading: "QA & Testing",

    cardText: (
      <>
        {"Ensuring smooth running of the application"} <br></br>
        <br></br>
        {
          "We take pride in the quality of our service, which means that our QA testers are an essential part of the team from the beginning of the project, assisting in the development of a safe, secure, and reliable digital product. Before any real user gets to access the platform, we put the app through intensive stress testing to guarantee that your product performs as required across all platforms and operating systems."
        }
      </>
    ),
  },
  {
    id: 6,
    cardHeading: "Product Release",

    cardText: (
      <>
        {"Launching your app to the market"} <br></br>
        <br></br>
        {
          "With the MVP ready there’s nothing left to do but to see how your app performs with real users! We’ll take care of the deployment and release, making sure that every individual part of the system is delivered on schedule and works smoothly, without any downtime."
        }
      </>
    ),
  },
  {
    id: 7,
    cardHeading: "Maintenance & Support",

    cardText: (
      <>
        {"Taking good care of your product"} <br></br>
        <br></br>
        {
          "Your custom digital product is released, but our cooperation doesn’t necessarily end here. We pride ourselves in building long lasting partnerships. If you’d like to upgrade your product with some additional features over time, if there is a particular enhancement that you’d like to try out or if you simply want us to take care of maintenance and support, we’re always happy to help!"
        }
      </>
    ),
  },
]
export const updatedDataEng = [
  {
    id: 1,
    cardHeading: "Understanding",

    cardText: (
      <>
        {"Understanding business needs and technical requirements"} <br></br>
        <br></br>
        {
          "Firstly, our data engineering team carries out the workshops and discovery calls with potential end-users. Then, we get all the necessary information from the technical departments."
        }
      </>
    ),
  },
  {
    id: 2,
    cardHeading: "Analysing",

    cardText: (
      <>
        {"Analysis of existing and future data sources"} <br></br>
        <br></br>
        {
          "At this stage, it is essential to go through current data sources to maximize the value of data. You should identify multiple data sources from which structured and unstructured data may be collected. During this step, our experts will prioritize and assess them."
        }
      </>
    ),
  },
  {
    id: 3,
    cardHeading: "Building and implementing",

    cardText: (
      <>
        {"Building and implementing Data Lake and Pipelines"} <br></br>
        <br></br>
        {
          "Data Lakes are the most cost-effective alternatives for storing data. A data lake is a data repository system that stores raw and processed structured and unstructured data files. A system like stores flat, source, transformed, or raw files."
        }
      </>
    ),
  },
  {
    id: 4,
    cardHeading: "Automating and deploying",

    cardText: (
      <>
        {"Automation and deployment"} <br></br>
        <br></br>
        {
          "The next step is one of the most important parts in data development consulting – DevOps. Our team develops the right DevOps strategy to deploy and automate the data pipeline."
        }
      </>
    ),
  },
  {
    id: 5,
    cardHeading: "Testing",

    cardText: (
      <>
        {"Testing"} <br></br>
        <br></br>
        {
          "Testing, measuring, and learning — are important at the last stage of the Data Engineering Process. DevOps automation is vital at this moment. Data Lakes could be established or accessed, using specific tools such as Hadoop or s3."
        }
      </>
    ),
  },
]
